import { Ability, AbilityBuilder } from '@casl/ability';
import store from '../../redux/store';
import isInRole from '../../utility/helpers/isInRole';
import { isLFPSource } from '../../utility/helpers/utils';

const allRules = {
    Lawyer: {
        2: {
            Can: [
                'manage|lawyer_referrals',
                'manage|lawyer_leads',
                'manage|referral',
                'read|profile',
                'manage|matters',
                'manage|matter_appointments',
                'manage|matter_messages',
                'manage|matter_files',
                'manage|matter_notes',
                'manage|matter_tasks',
                'manage|lrs_contacts',
                'manage|matter_expenses',
                'manage|conference',
                'manage|appointments',
                'manage|pending_referral',
            ],
            Cannot: [],
        },
        3: {
            Can: [
                'manage|lawyer_referrals',
                'read|profile',
                'manage|lrs_contacts',
                'manage|us_lawyer_leads',
                'manage|us_lawyer_matters',
                'manage|conference',
                'manage|pending_referral',
                'manage|matter_appointments',
            ],
            Cannot: [],
        },
        lfp: {
            Can: [
                'read|profile',
                'manage|lfp_lawyer_referrals',
                'manage|lfp_contacts',
                'manage|lfp_appointments',
                'manage|conference',
                'manage|appointments',
                'manage|pending_referral',
                //'manage|matters',
            ],
            Cannot: [],
        },
        5: {
            Can: [
                'manage|lawyer_referrals',
                'read|profile',
                'manage|lrs_contacts',
                'manage|us_lawyer_leads',
                'manage|us_lawyer_matters',
                'manage|conference',
                'manage|pending_referral',
                'manage|matter_appointments',
                'manage|acba_matter_tasks',
            ],
            Cannot: [],
        },
        [process.env.REACT_APP_SCBA_SOURCE_ID]: {
            Can: [
                'manage|lawyer_referrals',
                'read|profile',
                'manage|lrs_contacts',
                'manage|us_lawyer_leads',
                'manage|us_lawyer_matters',
                'manage|conference',
                'manage|pending_referral',
                'manage|matter_appointments',
                'manage|acba_matter_tasks',
            ],
            Cannot: [],
        },
        [process.env.REACT_APP_MNLRIS_SOURCE_ID]: {
            Can: [
                'manage|lawyer_referrals',
                'read|profile',
                'manage|lrs_contacts',
                'manage|us_lawyer_leads',
                'manage|us_lawyer_matters',
                'manage|conference',
                'manage|pending_referral',
                'manage|matter_appointments',
                'manage|acba_matter_tasks',
            ],
            Cannot: [],
        },
        [process.env.REACT_APP_QASE_DEMO_SOURCE_ID]: {
            Can: [
                'manage|lawyer_referrals',
                'read|profile',
                'manage|lrs_contacts',
                'manage|us_lawyer_leads',
                'manage|us_lawyer_matters',
                'manage|conference',
                'manage|pending_referral',
                'manage|matter_appointments',
                'manage|acba_matter_tasks',
            ],
            Cannot: [],
        },
        [process.env.REACT_APP_EPCB_SOURCE_ID]: {
            Can: [
                'manage|lawyer_referrals',
                'read|profile',
                'manage|lrs_contacts',
                'manage|us_lawyer_leads',
                'manage|us_lawyer_matters',
                'manage|conference',
                'manage|pending_referral',
                'manage|matter_appointments',
                'manage|acba_matter_tasks',
            ],
            Cannot: [],
        },
        [process.env.REACT_APP_WBA_SOURCE_ID]: {
            Can: [
                'manage|lawyer_referrals',
                'read|profile',
                'manage|lrs_contacts',
                'manage|us_lawyer_leads',
                'manage|us_lawyer_matters',
                'manage|conference',
                'manage|pending_referral',
                'manage|matter_appointments',
                'manage|acba_matter_tasks',
            ],
            Cannot: [],
        },
        [process.env.REACT_APP_MSBA_SOURCE_ID]: {
            Can: [
                'manage|lawyer_referrals',
                'read|profile',
                'manage|lrs_contacts',
                'manage|us_lawyer_leads',
                'manage|us_lawyer_matters',
                'manage|conference',
                'manage|pending_referral',
                'manage|matter_appointments',
                'manage|acba_matter_tasks',
            ],
            Cannot: [],
        },
    },
    Client: {
        Can: [
            'read|profile',
            'manage|referral',
            'manage|matters',
            'manage|matter_appointments',
            'manage|matter_messages',
            'manage|matter_files',
            'manage|matter_notes',
            'manage|matter_tasks',
            'manage|matter_expenses',
        ],
        Cannot: [
            'unshare|matter_files',
            'unshare|matter_notes',
            'create|matter_tasks',
            'create|matter_appointments',
            'create|matter_expenses',
        ],
    },
    'LRS Agent': {
        Can: [
            'manage|lrs_referral',
            'manage|lawyers',
            'manage|lrs_clients',
            'manage|table_list_reports',
            'manage|stats_reports',
            'manage|referral_reports',
            'manage|lawyer_reports',
            'manage|elc_reports',
            'manage|collections',
            'manage|referral',
            'manage|activities',
        ],
        Cannot: [],
    },
    Supervisor: {
        Can: [
            'read|lawyer_referrals',
            'read|referral',
            'read|profile',
            'read|matters',
            'read|matter_appointments',
            'read|matter_messages',
            'read|matter_files',
            'read|matter_notes',
            'read|matter_tasks',
            'read|lrs_contacts',
            'read|matter_expenses',
        ],
        Cannot: [],
    },
    'ELC Admin': {
        Can: [
            'read|lawyer_referrals',
            'read|referral',
            'read|profile',
            'read|matters',
            'read|matter_appointments',
            'read|matter_messages',
            'read|matter_files',
            'read|matter_notes',
            'read|matter_tasks',
            'read|lrs_contacts',
        ],
        Cannot: [],
    },
    Agent: {
        Can: [
            'manage|ecba-referrals',
            'manage|ecba_lawyers',
            'manage|ecba_clients',
            'manage|ecba_reports',
            'manage|matter_payment',
            'manage|matter_agent_appointments',
            'manage|matter_updates',
            'manage|agent_settings',
        ],
        Cannot: [],
    },
};

//  Read ability from localStorage
// * Handles auto fetching previous abilities if already logged in user
// ? You can update this if you store user abilities to more secure place
// ! Anyone can update localStorage so be careful and please update this
const defineRulesFor = (auth) => {
    const roles = auth.loggedUser.roles;
    const { can, rules, cannot } = new AbilityBuilder();
    if (roles) {
        const role = roles[0].name;
        if (allRules[role]) {
            let canRules = [];
            let cannotRules = [];
            let isLFP = isLFPSource(auth.loggedUser.source);
            let key = isLFP ? 'lfp' : auth.loggedUser.source[0].id;
            if (isInRole(roles, 'lawyer')) {
                canRules = allRules[role][key]['Can'];
                cannotRules = allRules[role][key]['Cannot'];
            } else {
                canRules = allRules[role]['Can'];
                cannotRules = allRules[role]['Cannot'];
            }

            if (
                role === 'Agent' &&
                key == process.env.REACT_APP_APB_SOURCE_ID
            ) {
                canRules.push(
                    'read|apb_reports',
                    // 'manage|table_list_reports',
                    // 'manage|stats_reports',
                    // 'manage|referral_reports',
                    // 'manage|lawyer_reports',
                );
            }

            canRules.forEach((rule) => {
                const currentRule = rule.split('|');
                can(currentRule[0], currentRule[1]);
            });
            cannotRules.forEach((rule) => {
                const currentRule = rule.split('|');
                cannot(currentRule[0], currentRule[1]);
            });
        }
    }

    return rules;
};

const ability = new Ability(defineRulesFor(store.getState().auth));

store.subscribe(() => {
    let auth = store.getState().auth;

    ability.update(defineRulesFor(auth));
});

export default ability;
